import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Technology from '../components/Technology'
import Testimonial from '../components/Testimonial'

import logoJavascript from '../images/tech_javascript.svg'
import logoReact from '../images/tech_react.svg'
import logoNextjs from '../images/tech_nextjs.svg'
import logoGatsby from '../images/tech_gatsby.svg'
import logoTypesctipt from '../images/tech_typescript.svg'
import logoTailwind from '../images/tech_tailwind.svg'
import logoRedux from '../images/tech_redux.svg'
import logoGraphql from '../images/tech_graphql.svg'
import logoNodejs from '../images/tech_nodejs.svg'
import logoHapi from '../images/tech_hapi.svg'
import logoPostgresql from '../images/tech_postgres.svg'
import logoMysql from '../images/tech_mysql.svg'
import webAgencyImg from '../images/web_agency.svg'
// import testimonialColleoni from '../images/testimonial_colleoni.svg'
import testimonialBrandolini from '../images/testimonial_brandolini.png'
import logoCisl from '../images/testimonial_cisl.svg'

const TechnologyPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net technology stack: React, Redux, Next.js, Gatsby, TypeScript, Tailwind, GraphQL, Node.js, mySql, PostgreSql."
        keywords={[
          `F2.net`,
          `JavaScript`,
          `React`,
          `Redux`,
          `Next.js`,
          `Gatbsby`,
          `Typescript`,
          `Tailwind`,
          `GraphQL`,
          `Node.js`,
          `mysql`,
          `postgresql`,
        ]}
        title="Our Technology Stack"
      />

      <Hero>
        <h1 className="text-3xl sm:text-5xl text-center font-black leading-tight mb-3">
          Our Technology Stack
        </h1>
        <p className="text-xl text-center leading-relaxed text-gray-700">
          We work with the latest technologies and the more productive ones for
          your projects.
        </p>
      </Hero>

      <section className="px-6">
        <div className="flex flex-row flex-wrap max-w-3xl mx-auto justify-center sm:justify-between">
          <Technology logoUrl={logoJavascript} name="JavaScript" />
          <Technology logoUrl={logoReact} name="React" />
          <Technology logoUrl={logoNextjs} name="Next.js" />
          <Technology logoUrl={logoGatsby} name="Gatsby" />

          <Technology logoUrl={logoTypesctipt} name="TypeScript" />
          <Technology logoUrl={logoTailwind} name="Tailwind" />
          <Technology logoUrl={logoRedux} name="Redux" />
          <Technology logoUrl={logoGraphql} name="GraphQL" />

          <Technology logoUrl={logoNodejs} name="Node.js" />
          <Technology logoUrl={logoHapi} name="Hapi" />
          <Technology logoUrl={logoPostgresql} name="PostgreSQL" />
          <Technology logoUrl={logoMysql} name="MySQL" />
        </div>
      </section>

      <section className="bg-gray-200 py-12">
        <div className="max-w-4xl mx-auto px-6 md:flex md:items-center">
          <div className="flex-1 md:pr-10 text-center md:text-left">
            <h2 className="text-3xl font-extrabold mb-3">
              Are you a web agency?
            </h2>
            <p className="text-xl leading-relaxed text-gray-700">
              We collaborate with web agencies in Italy and abroad. We may
              create complete web applications for you, or, if you take care of
              the front-end development, we may just create the back-end side of
              the moon.
            </p>
          </div>

          <div className="flex-1 hidden md:block">
            <img alt="Web agencies" src={webAgencyImg} />
          </div>
        </div>
      </section>

      <section className="px-6 pt-12 pb-20">
        {/* <Testimonial
          authorJobTitle="IT Consultant @ Comune di Bergamo"
          authorName="Ing. Italo Colleoni"
          avatarImage={testimonialColleoni}
          quote={<span>F2.net team leverages all the <b>latest technologies</b> but they are very <b>pragmatic</b> when they design the solutions, in order to deploy the critical features on time.</span>}
        /> */}
        <Testimonial
          authorJobTitle="IT and DPO @ CISL Lombardia"
          authorName="Donato Brandolini"
          avatarImage={testimonialBrandolini}
          logoImage={logoCisl}
          quote={
            <span>
              We are happy to work with F2.net because they{' '}
              <b>understand our needs</b> and always deliver the product we had
              in mind. They use the <b>latest technologies</b> and put much care
              in <b>user experience</b> and data <b>security</b>, improving our
              productivity with tools our users love.
            </span>
          }
        />
      </section>
    </Layout>
  )
}

export default TechnologyPage
