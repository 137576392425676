import React from 'react'

const Technology = ({ name, logoUrl }) => {
  return (
    <div className="w-40 pb-16 flex flex-col justify-center">
      <img alt={name} className="w-24 h-24 mx-auto mb-6" src={logoUrl} />
      <p className="text-xl font-extrabold text-center">{name}</p>
    </div>
  )
}

export default Technology
